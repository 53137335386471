@font-face {
    font-family: 'Hattori Hanzo';
    src: local('Hattori Hanzo Light'), local('HattoriHanzo-Light'), url('../fonts/hattorihanzolight.woff2') format('woff2'), url('../fonts/hattorihanzolight.woff') format('woff'), url('../fonts/hattorihanzolight.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Hattori Hanzo';
    src: local('Hattori Hanzo Light Italic'), local('HattoriHanzo-LightItalic'), url('../fonts/hattorihanzolightitalic.woff2') format('woff2'), url('../fonts/hattorihanzolightitalic.woff') format('woff'), url('../fonts/hattorihanzolightitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Hattori Hanzo Rouble';
    src: url('../fonts/rouble.woff') format('woff'), url('../fonts/rouble.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Hattori Hanzo', sans-serif, serif;
    font-size: 22px;
    line-height: 1;
    overflow-y: scroll;
}

button {
    background-color: #e64a19;
    padding: 12px 20px;
    font-size: 22px;
    color: white;
    border: none;
    outline: none;
}

a {
    color: #e64a19;
}

.ac {
    text-align: center;
}

.al {
    text-align: left !important;
}
.fl {
    float: left;
}
.cb {
    clear: both;
}
img.fl {
    margin-right: 20px;
}
img.c {
    display: block;
    margin: 0 auto;
}

button.f32 {
    margin-top: 10px;
    font-size: 36px;
}

button.f33 {
    margin-top: 10px;
    margin-bottom: 120px;
    padding: 20px 90px;
    font-size: 36px;
}

button.phone {
    background: #e64a19 url("/img/phone.png") no-repeat left 10px center;
    margin-top: 10px;
    padding: 20px;
    padding-left: 50px;
    display: block;
}

button:hover {
    background-color: #d50000;
    cursor: pointer;
}

h1 {
    font-family: 'Hattori Hanzo', sans-serif, serif;
    color: black;
    font-size: 36px;
    font-weight: normal;
    margin: 0;
}

h2 {
    font-family: 'Hattori Hanzo', sans-serif, serif;
    color: black;
    font-size: 36px;
    font-size: 50px;
    font-weight: normal;
    margin: 0;
}

.docid74 h2 {
    font-size: 50px;
}

h2 a {
    color: black;
}

h3 {
    font-family: 'Hattori Hanzo', sans-serif, serif;
    color: black;
    font-size: 26px;
    font-weight: normal;
    margin: 0;
}

.cb {
    clear: both;
}

.top-menu {
    /*height: 49px;*/
}

.top-menu__fixed {
    /*position: fixed;*/
    width: 100%;
    height: 100px;
    z-index: 1000;
    background: #e64a19;
}

.top-menu ul {
    background: #e64a19;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: stretch;
    list-style-type: none;
    width: 1140px;
    margin: 0 auto;
    padding: 0;
}
.top-menu ul + ul {
    margin-top: 2px;
}

.top-menu ul li {

}

.top-menu ul li span,
.top-menu ul li a {
    display: block;
    padding: 5px 24px;
    /*background: #37a33d;*/
    color: white;
    font-size: 22px;
    text-decoration: none;
    position: relative;
    white-space: nowrap;
    z-index: 2;
    cursor: pointer;
}

.top-menu ul li a.highlighted {
    background: #37a33d;
}

.top-menu ul li a.highlighted1 {
    background: #e5bb1d;
}

.top-menu ul li a.highlighted2 {
    background: #1d8de5;
}

.top-menu ul li a.highlighted3 {
    background: #1cd5d9;
}

.top-menu ul li a.highlighted4 {
    background: #ff81a1;
}

.top-menu ul li span:before,
.top-menu ul li a:before {
    position: absolute;
    content: '';
    width: 2px;
    top: 50%;
    right: -1px;
    height: 20px;
    margin-top: -10px;
    background: white;
}

.top-menu ul li:last-child span:before,
.top-menu ul li:last-child a:before {
    display: none;
}

.top-menu ul li span:hover,
.top-menu ul li.active span,
.top-menu ul li a:hover,
.top-menu ul li.active a {
    background: #d50000;
    z-index: 1;
}

.block-1 {
    position: relative;
    background: #c9c9c9;
    width: 100%;
    min-height: 580px;
}

.block-1:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    opacity: 0;
    background: url("/img/block-1.jpg") no-repeat center 50%;
    background-size: cover !important;
    transition: 1.5s;
}
.block-1,
.block-1 .wrap {
	max-height: 945px;
}

.docid3 .block-1:before {
    background: url("/img/_block-1.jpg") no-repeat center 50%;
}

.docid73 .block-1:before {
    background: url("/img/t73_block-1.jpg") no-repeat center 50%;
}

.docid74 .block-1:before {
    background: url("/img/t74_block-1.jpg") no-repeat center 50%;
}

.docid75 .block-1:before {
    background: url("/img/t75_block-1.jpg") no-repeat center 50%;
}

.docid76 .block-1:before {
    background: url("/img/t76_block-1.jpg") no-repeat center 50%;
}

.docid77 .block-1:before {
    background: url("/img/t77_block-1.jpg") no-repeat center 50%;
}

.docid78 .block-1:before {
    background: url("/img/t78_block-1.jpg") no-repeat center 50%;
}

.docid79 .block-1:before {
    background: url("/img/t79_block-1.jpg") no-repeat center 50%;
}

.docid80 .block-1:before {
    background: url("/img/t80_block-1.jpg") no-repeat center 50%;
}

.docid82 .block-1:before {
    background: url("/img/t82_block-1.jpg") no-repeat center 50%;
}
.docid84 .block-1:before {
    background: url("/img/t84_block-1.jpg") no-repeat center 50%;
}

.docid85 .block-1:before {
    background: url("/img/t85_block-1.jpg") no-repeat center 50%;
}
.docid86 .block-1:before {
    background: url("/img/t86_block-1.jpg") no-repeat center 50%;
}
.docid87 .block-1:before,
.docid89 .block-1:before {
    background: url("/img/t87_block-1.jpg") no-repeat center 50%;
}
.docid88 .block-1:before {
    background: url("/img/t88_block-1.jpg") no-repeat center 50%;
}
.docid90 .block-1:before {
    background: url("/img/t90B_block-1.jpg") no-repeat center 50%;
}

.block-1.loaded:before {
    opacity: 1;
}

.block-1 .wrap {
    /*min-height: 780px;*/
    width: 1200px;
    /*display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap:wrap;
    -ms-flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    justify-content: space-between;
    -ms-flex-line-pack: stretch;
    align-content:space-between;
    -webkit-align-content: space-between;
    -ms-flex-align: start;

    */
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    margin: 0 auto;
    color: white;
    position: relative;
    transition: 1s;
    opacity: 0;
}

.b-content__wrap {
    margin: 0 auto;
    width: 1200px;
}

.b-content h3 {
    font-weight: bold;
}

.b-content p, .b-content li {
    text-indent: 1.5em;
}

.block-1 .wrap > div {
    width: 100%;
}

.block-1 .wrap > div:first-child {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.block-1 .wrap > div:last-child {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.block-1.loaded .wrap {
    opacity: 1;
}

.block-1 a {
    color: white;
    text-decoration: none;
}

.block-1 .wrap > div {
    overflow: hidden;
    width: 100%;
    padding: 0;
}

.block-1 .wrap > div:first-child {
    padding-top: 40px;
}

.block-1 .wrap > div a#logo {
    width: 182px;
    height: 71px;
    display: block;
    float: left;
    background: url("/img/logo.png") no-repeat top center;
}

.site_desc {
    display: block;
    position: absolute;
    top: 115px;
    left: 0px;
    width: 180px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #e64a19;
}

.text_bg {
    margin-bottom: 20px;
    padding: 5px 0;
    background: rgba(255, 255, 255, 0.6);
    color: #000000;
}

.text_bg strike {
    color: #000000;
}

.block-1 .wrap > div .contacts {
    float: right;
    white-space: nowrap;
    font-size: 50px;
    text-align: right;
}

.block-1 .wrap > div .contacts a {
    display: block;
    color: #e64a19;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.6);
    /*text-shadow:
            -0   -1px 0   #FFFFFF,
            0   -1px 0   #FFFFFF,
            -0    1px 0   #FFFFFF,
            0    1px 0   #FFFFFF,
            -1px -0   0   #FFFFFF,
            1px -0   0   #FFFFFF,
            -1px  0   0   #FFFFFF,
            1px  0   0   #FFFFFF,
            -1px -1px 0   #FFFFFF,
            1px -1px 0   #FFFFFF,
            -1px  1px 0   #FFFFFF,
            1px  1px 0   #FFFFFF,
            -1px -1px 0   #FFFFFF,
            1px -1px 0   #FFFFFF,
            -1px  1px 0   #FFFFFF,
            1px  1px 0   #FFFFFF;*/
}

.block-1 .wrap > div:last-child {
    text-align: center;
    transition: 1s;
    padding-bottom: 50px;
}

.block-1 .wrap > div:last-child p {
    overflow: hidden;
    padding: 0 60px;
    position: relative;
    font-size: 36px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
    margin: 10px 0;
}

.block-1 .wrap > div:last-child p:first-child:before {
    content: '';
    position: absolute;
    width: 180px;
    height: 5px;
    background: #000;
    left: 50%;
    right: 0;
    bottom: -2px;
    margin-left: -100px;
}

.cb {
    clear: both;
}

.block-1 .wrap .l {
    text-align: center;
    /*font-size: 36px;*/
}

.block-1 .wrap .r {
    margin-bottom: 10px;
    /*width: 40%;*/
    text-align: center;
    /*font-size: 36px;*/
}

.block-1 .wrap > div:last-child h1 {
    display: inline-block;
    font-size: 70px;
    text-transform: none;
    font-style: normal;
    white-space: nowrap;
}

.block-1 .wrap > div:last-child span.s2 {
    font-size: 50px;
    margin-top: 10px;
    color: #e64a19;
}

.docid73 .block-1 .wrap > div:last-child span.s2 {
    /*margin-left: 80px;*/
}

.docid74 .block-1 .wrap > div:last-child span.s1 {
    /*margin-left: 80px;*/
}

.docid74 .block-1 .wrap > div:last-child span.s2 {
    /*margin-left: 165px;*/
}

/*
.small.docid74 .block-1 .wrap > div:last-child span.s2 {
	margin-left: 15px;
}

body.small.docid73 .block-1 .wrap > div:last-child span.s2 {
}
body.small .block-1 .wrap > div:last-child p {
	overflow: hidden;
	padding: 6px 60px;
	position: relative;
	font-size: 22px;
}
body.small .block-1 .wrap > div:last-child span.s1 {
	font-size: 36px;
}
body.small .block-1 .wrap > div:last-child span.s2 {
	font-size: 30px;
	width: 400px;
}*/

header {
    position: relative;
    background: #eeeeee;
    width: 100%;
    height: 205px;
    display: block;
}

header:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    opacity: 0;
    background: url("/img/head-bg-sub.jpg") no-repeat center;
    background-size: cover;
    transition: 1.5s;
}

header.loaded:before {
    opacity: 1;
}

header .wrap {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    padding-top: 40px;
}

header .wrap #logo {
    width: 182px;
    height: 101px;
    display: block;
    float: left;
    background: url("/img/logo.png") no-repeat center;
}

header .wrap .contacts {
    float: right;
    white-space: nowrap;
    font-size: 50px;
    text-align: right;
}

header .wrap .contacts a {
    color: white;
    text-decoration: none;
}

main,
main.full-width .wrap {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    display: block;
    position: relative;
}

.docid4 main .wrap {
    overflow: visible;
}

main.full-width {
    width: 100%;
}

main.full-width .wrap h2 {
    text-align: center;
    margin: 60px 0;
}

main.full-width .stars .wrap h2 {
    margin-bottom: 0;
}

main.full-width .form-block {
    margin-bottom: 100px;
}

main.full-width .form-block h2 {
    margin-bottom: 0;
}

main.full-width .delivery-payment .wrap ul.delivery {
    padding-bottom: 200px;
}

main.full-width .manufacture {
    background: white;
    opacity: 1;
    color: black;
}

main.full-width .manufacture h2 {
    color: black;
    margin-top: 0;
}

main.full-width .manufacture .sm_prev,
main.full-width .manufacture .sm_next,
main.full-width .reviews #reviews_slider .sm_prev,
main.full-width .reviews #reviews_slider .sm_next {
    border-color: black;
}

main.full-width .manufacture .sm_nav-item i,
main.full-width .reviews #reviews_slider .sm_nav-item i {
    background-color: #616161;
}

main.full-width .manufacture .sm_nav-item.active i,
main.full-width .manufacture .sm_nav-item:hover i,
main.full-width .reviews #reviews_slider .sm_nav-item.active i,
main.full-width .reviews #reviews_slider .sm_nav-item:hover i {
    background-color: #e64a19;
}

main.full-width .manufacture .wrap ul.thesis {
    color: black;
}

main.full-width .opt-form .wrap {
    padding: 100px 0;
}

main.full-width .opt-form .wrap h2 {
    margin-bottom: 0;
}

main.full-width .certs .wrap h2 {
    margin: 20px 0 30px;
    padding-top: 30px;
}

main.full-width .reviews {
    background: white;
    opacity: 1;
}

main.full-width .reviews .wrap {
    overflow: visible;
}

main.full-width .structure .wrap h2 {
    margin: 0;
}

main.full-width #reviews_slider .link,
main.full-width #reviews_slider .link a,
main.full-width #reviews_slider,
main.full-width #reviews_slider .title,
main.full-width #reviews_slider .desc,
main.full-width .reviews .wrap h2 {
    color: black;
}

main.full-width .reviews .wrap h2 {
    margin-top: 0;
}

main .catalog {
    width: 900px;
    float: left;
}

main .catalog h2 {
    margin-top: 60px;
}

main .catalog ul li > div.img {
    width: 255px;
    border-color: white;
}

main > aside {
    float: right;
    width: 300px;
    margin-top: 160px;
    box-sizing: border-box;
    padding-left: 20px;
}

main > aside ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

main > aside ul li {
    margin-bottom: 10px;
}

main > aside ul a {
    display: inline-block;
    padding: 10px 15px 10px 0;
    color: black;
    font-size: 22px;
    text-decoration: none;
}

main > aside ul a:hover,
main > aside ul li.active a {
    color: #e64a19;
}

main aside .banner {
    width: 300px;
    height: 488px;
    background-repeat: no-repeat;
    background-position: left top;
}

main .wrap .breadcrumbs {
    padding: 30px 0;
    font-size: 22px;
}

main .wrap .breadcrumbs a {
    text-decoration: underline;
    color: black;
}

main .wrap > ul.item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    list-style-type: none;
    padding: 0;
}

main .wrap > ul.item > li:first-child {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    width: 30%;
}

main .wrap > ul.item > li:last-child {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    width: 70%;
    padding: 0 30px;
}

main .wrap > ul.item ul {
    padding: 0;
}

.add-review form {
    text-align: center;
    margin-top: 58px;
    margin-bottom: 100px;
}

.add-review form h2,
.add-review form h2 span {
    font-size: 50px;
}

.add-review form span {
    font-size: 36px;
}

.add-review form > div {
    margin: 30px 0;
}

.add-review form .textarea-file {
    overflow: hidden;
}

.add-review form .textarea-file textarea {
    float: left;
    margin-left: 83px;
    width: 680px;
    resize: none;
    box-sizing: border-box;
}

.add-review form .textarea-file input {
    float: left;
    width: 182px;
    margin-left: 22px;
    padding: 54px 60px;
    margin-top: 20px;
    font-size: 22px;
}

.add-review form input,
.add-review form textarea {
    border: 5px solid #616161;
    border-radius: 5px;
    padding: 20px 60px;
    text-align: center;
    font-size: 27px;
    transition: .2s;
    margin-right: 20px;
    outline: 0;
    width: 365px;
    font-family: 'Hattori Hanzo', sans-serif, serif;
}

.add-review form textarea {
    display: block;
    width: 68%;
    box-sizing: border-box;
    margin: 20px auto;
    height: 150px;
    text-align: left;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    .add-review form .textarea-file textarea {
        height: 141px;
    }
}

.add-review form div input:last-child {
    margin-right: 0;
}

.add-review form input:hover {
    border-color: #e64a19;
}

.add-review form button {
    padding: 20px 90px;
    font-size: 36px;
}

ul.item .properties {
    overflow: hidden;
}

ul.item .properties > div p {
    line-height: 1.2;
    font-size: 22px !important;
}

ul.item .properties > div:first-child {
    float: left;
    width: 535px;
}

ul.item .properties > div:first-child h1 {
    margin-bottom: 10px;
}

ul.item .properties > div:first-child p {
    margin: 0;
    line-height: 1.2;
    font-size: 22px;
}

ul.item .properties > div:last-child {
    /*float: right;*/
    position: absolute;
    right: 30px;
    top: 80px;
}

ul.item .properties > div:last-child span {
    display: block;
}

ul.item .properties > div:last-child span em {
    font-family: 'Hattori Hanzo Rouble', sans-serif, serif;
    font-style: normal;
    font-weight: normal;
}

ul.item .properties > div:last-child span.old-price {
    text-decoration: line-through;
    font-size: 38px;
    color: #000;
    padding: 0;
}

ul.item .properties > div:last-child span.current-price {
    font-size: 55px;
    color: #e64a19;
}

ul.item .full-text {
    font-size: 22px;
    line-height: 1.2;
}

ul.item form {
    text-align: center;
    margin-top: 58px;
}

ul.item form h2,
ul.item form h2 span {
    font-size: 50px;
}

ul.item form span {
    font-size: 36px;
}

ul.item form > div {
    margin: 30px 0;
}

ul.item form input {
    border: 5px solid #616161;
    border-radius: 5px;
    padding: 21px 17px;
    text-align: center;
    font-size: 27px;
    transition: .2s;
    margin-right: 20px;
    outline: 0;
}

ul.item form div input:last-child {
    margin-right: 0;
}

ul.item form input:hover {
    border-color: #e64a19;
}

ul.item form button {
    padding: 20px 90px;
    font-size: 36px;
}

.additionals h2 {
    margin-top: 80px;
    text-align: center;
}

#items-slide {
    position: relative;
    height: 117px;
}

#items-slide ul img {
    min-height: 110px;
    display: inline-block;
    margin-left: -15px;
    border: 2px solid white;
}

#items-slide ul img.active,
#items-slide ul img:hover {
    border-color: #e64a19;
    cursor: pointer;
}

#items-slide ul li img:first-child {
    margin-left: 0;
}

.about .wrap {
    width: 1200px;
    margin: 0 auto;
}

.about h2,
.about .text__title {
    text-align: center;
    margin: 40px 0;
}
.about .text__title {
    text-align: left;
    color: #fff;
    font-size: 50px;
}
.about .text__title2 {
    font-family: Hattori Hanzo,sans-serif;
    font-size: 26px;
    font-weight: 400;
    margin: 0;
}


.about .pics {
    overflow: hidden;
}

.about .pics > div {
    float: left;
    width: 365px;
    height: 247px;
    margin: 16px 0 22px 37px;
    opacity: 0;
    transition: 1.5s opacity, .2s border;
    background-color: white;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    border: 5px solid white;
    border-radius: 5px;
    color: white;
    position: relative;
}

.about .pics > div:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 155px;
    height: 110px;
    background: url("/img/icon-set-5.png") no-repeat;
}

/*.about .pics > div:nth-child(5):before{background-position: top left -160px;left: -15px;top: 10px;}*/
.about .pics > div:nth-child(3):before {
    left: 20px;
}

.about .pics > div:nth-child(4):before {
    top: 16px;
    left: 15px;
    width: 110px;
    height: 61px;
    background: url('/img/i1.png') 0 0 no-repeat;
}

.about .pics > div:nth-child(5):before {
    top: 20px;
    left: 20px;
    width: 56px;
    height: 61px;
    background: url('/img/i2.png') 0 0 no-repeat;
}

.about .pics > div:nth-child(2):before {
    background-position: top left -256px;
    top: 14px;
    left: -25px;
    background-size: cover;
    width: 140px;
    height: 90px;
}

.about .pics > div:nth-child(6):before {
    background-position: top left -480px;
    left: -30px;
}

.about .pics > div:hover {
    border: 5px solid #e64a19;
    border-radius: 5px;
}

.about .pics > div:hover:before {
    display: none;
}

.about .pics > div:hover > div:first-child {
    display: none;
}

.about .pics > div:hover > div:last-child {
    display: block;
}

.about .pics > div.big {
    width: 777px;
    height: 540px;
    margin-top: 0;
    margin-left: 0;
}

.about .pics > div.big:before {
    display: none;
}

@media only screen and (max-device-width: 1366px) {
    .about .pics > div {
        float: left;
        width: 365px;
        height: 206px;
        margin: 26px 0 0 52px;
        opacity: 0;
        transition: 1.5s opacity, .2s border;
        background-color: #eee;
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
        box-sizing: border-box;
        border: 5px solid white;
        color: white;
        position: relative;
    }

    .about .pics > div.big {
        width: 783px;
        height: 438px;
        margin-top: 0;
        margin-left: 0;
        background-position: top left;
    }
}

.about .pics > div:nth-child(2) {
    margin-top: 0;
}

.about .pics > div:nth-child(4) {
    margin-left: 0;
}

.about .pics > div .text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    font-size: 22px;
    display: none;
}

.about .pics > div .text.show {
    display: block;
}

.about .pics > div .text h2,
.about .pics > div .text h3 {
    color: white;
    text-align: left;
}

.about .pics > div .text p {
    margin: 0;
}

.about .wrap > ul {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding: 0;
    margin-top: 50px;
}

.about .wrap > ul li {
    padding: 30px;
    font-size: 22px;
    width: 280px;
    text-align: center;
    box-sizing: border-box;
    border: 5px solid transparent;
    border-radius: 5px;
    transition: .2s;
}

.about .wrap > ul li:hover {
    border: 5px solid #e64a19;
    border-radius: 5px;
}

.about .wrap > ul li h2 {
    margin: 0 0 20px;
}

.catalog .wrap {
    width: 1200px;
    margin: 0 auto;
}

.catalog h2 {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 40px;
}

.catalog ul,
ul.items {
    list-style-type: none;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    padding: 0;
}

.catalog ul li,
ul.items li {
    padding: 0;
    margin: 20px;
    width: 260px;
    text-align: center;
}

.catalog ul li > div.img,
ul.items li > div.img {
    width: 260px;
    height: 356px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transition: 1.5s opacity, .2s border;
    position: relative;
    overflow: hidden;
    border: 5px solid transparent;
    border-radius: 5px;
}

.catalog ul li:hover,
ul.items li:hover {
    cursor: pointer;
}

.catalog ul li:hover > div.img,
ul.items li:hover > div.img {
    border: 5px solid #e64a19;
    cursor: pointer;
}

.full-img {
    position: relative;
    overflow: hidden;
}

.catalog ul li > div.img.hit:before,
ul.items li > div.img.hit:before,
.full-img.hit:before {
    position: absolute;
    content: 'ХИТ!';
    top: -15px;
    right: -49px;
    font-size: 31px;
    text-transform: uppercase;
    background: #d50000;
    color: white;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    padding: 40px 45px 5px;
}

.catalog ul li > div.img.discount:before,
ul.items li > div.img.discount:before,
.full-img.discount:before {
    position: absolute;
    content: 'Акция!';
    top: -11px;
    right: -61px;
    font-size: 27px;
    text-transform: uppercase;
    background: #d50000;
    color: white;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    padding: 40px 45px 5px;
}

.catalog ul li > div.img a,
ul.items li > div.img a {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    background-color: #e64a19;
    padding: 12px 20px;
    font-size: 26px;
    color: white;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
}

.catalog ul li .title,
ul.items li .title {
    font-size: 27px;
    margin: 10px 0;
}

.catalog ul li .price span,
ul.items li .price span.current-price {
    padding: 0 10px 10px;
    display: block;
    font-size: 26px;
    color: #e64a19;
}

.catalog ul li .price span.old-price,
ul.items li .price span.old-price {
    /*display:inline-block;*/
    text-decoration: line-through;
    line-height: 17px;
    font-size: 17px;
    color: #000;
    padding: 0;
}

.catalog ul li .price span em,
ul.items li .price span em {
    font-family: 'Hattori Hanzo Rouble', sans-serif, serif;
    font-style: normal;
}

.stars .wrap {
    width: 1200px;
    margin: 0 auto;
}

.stars h2 {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 10px;
    font-size: 50px;
}

.stars .wrap > span {
    font-size: 36px;
    text-align: center;
    display: block;
    margin-bottom: 40px;
}

.stars .wrap > p {
    font-size: 22px;
    line-height: 1.2;
}

.stars ul {
    list-style-type: none;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    padding: 0;
    width: 1200px;
    margin: 0 auto 50px;
}

.stars ul li {
    width: 225px;
    height: 311px;
    /*background: rgba(0,0,0,0.5);*/
    background-color: #000;
    /*opacity: 0;*/
    border: 5px solid #fff;
    border-radius: 5px;
    /*margin: 6px;*/
    position: relative;
    transition: 1.5s opacity, .2s border;
}

.stars ul li div.img-star {
    position: relative;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5 !important;
    transition: 1.5s opacity;
    /*box-sizing: border-box;*/
}

.stars ul li div.img-star:hover {
    opacity: 1 !important;
}

.stars ul li:nth-child(6n+6),
.stars ul li:nth-child(1) {
    margin-left: 0;
}

.stars ul li:nth-child(5n+5) {
    margin-right: 0;
}

.stars ul li span {
    display: block;
    position: absolute;
    /*content: attr(data-title);*/
    bottom: 20px;
    left: 0;
    right: 0;
    color: white;
    font-size: 36px;
    text-align: center;
}

.stars ul li:hover {
    border: 5px solid #e64a19;
}

.b-build {
    position: relative;
}

.b-build:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
}

.b-build .wrap {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    overflow: visible !important;
    z-index: 10;
}

.b-build h2 {
    text-align: center;
    padding-top: 80px;
    margin-bottom: 40px;
    color: #ffffff;
}

.b-build__block {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
    /*-webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;*/
}

.b-build__model {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;

    padding: 0;
    /*margin-left: 20px;*/
    margin-bottom: 20px;
    padding: 10px 20px;
    width: 345px;
    /*height: 250px;*/
    border: 1px solid #000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: rgba(41, 182, 246, 0.8);
    text-align: center;
}

.b-build__model__title {
    display: block;
    padding-top: 10px;
    font-size: 36px;
}

.b-build__model__stitle {
    display: block;
    padding-top: 10px;
    font-size: 22px;
    color: #fff;
}

.b-build__model__text {
    text-align: left;
    font-size: 22px;
}
/* /.b-build */

/* .b-equipment */
.b-equipment .wrap {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    overflow: visible !important;
    z-index: 10;
}

.b-equipment h2 {
    text-align: center;
    padding-top: 80px;
    margin-bottom: 40px;
    /*color: #ffffff;*/
}
.b-equipment__block {
    text-align: center;
}
.b-equipment__block div {
    padding-bottom: 15px;
    font-size: 36px;
}
.b-equipment__block div span {
    font-size: 50px;
    font-weight: bold;
    color: #e64a19;
}

/* .b-plus */
.b-plus .wrap {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    overflow: visible !important;
    z-index: 10;
}

.b-plus h2 {
    margin: 60px 0 0 0;
    text-align: center;
    /*padding-top: 10px;*/

}

.b-plus h2 span {
    color: #d50000;
}

.b-plus__block {
    font-size: 36px;
    line-height: 140%;
}

.b-plus__block__l,
.b-plus__block__r {
    float: left;
    margin-left: 2%;
    width: 44%;
}

/* .b-adv */
.b-adv .wrap {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    overflow: visible !important;
    z-index: 10;
}

.b-adv__block__l,
.b-adv__block__r {
    float: left;
    margin-left: 2%;
    width: 44%;
    height: 304px;
}

.b-adv__block__l {
    background: url('/img/garant.png') 100px -35px no-repeat;
}

.b-adv__block__r {
    text-align: center;
}

.b-adv h2 {
    margin-top: 45px;

}

.b-adv p {
    font-size: 36px;
}

.block-2 {
    height: 666px;
    opacity: 0;
    transition: 1.5s;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.block-2 .wrap {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    overflow: visible !important;
}

.block-2 .title {
    padding: 105px 0 0 510px;
    text-align: left !important;
    font-size: 70px;
    color: #ffffff;
}

.block-2 .made-in-russia {
    top: 450px;
    right: 60px;
}

.made-in-russia {
    display: block !important;
    position: absolute;
    top: 60px;
    right: 40px;
    padding: 20px;
    transform: rotate(-8deg);
    background: #d50000;
    font-size: 50px;
    color: #ffffff;
    z-index: 10;
}

.cb {
    clear: both;
}

.structure {
    margin-top: 10px;
    height: 700px;
    opacity: 0;
    transition: 1.5s;
    background-position: 50% 70px;
    background-repeat: no-repeat;
    /*background-size: cover;*/
}

.structure .wrap {
    position: relative;
    width: 1200px;
    margin: 0 auto;
}

.structure .title {
    text-align: center;
    /*padding-top: 55px;*/
    margin: 0;
    font-size: 50px;

}

.structure p {
    position: absolute;
    width: 178px;
    text-align: center;
    font-size: 22px;
}

.structure .p1 {
    top: 240px;
    left: 65px;
}

.structure .p2 {
    top: 240px;
    right: 65px;
}

.structure .p3 {
    top: 520px;
    left: 230px;
}

.structure .p4 {
    top: 520px;
    left: 520px;
}

.structure .p5 {
    top: 520px;
    left: 740px;
    width: 260px;
}

.b-discount .wrap {
    position: relative;
    width: 1200px;
    margin: 0 auto;
}
.b-discount h2 {
    text-align: center;
}

.structure_bt {
    /*margin-top: 510px;*/
    text-align: center;
    font-size: 50px;
}

.structure_bt span {
    font-size: 70px;
    color: #d50000;
}

.structure_bt .l {
    float: left;
    padding-left: 230px;
    width: 320px;
    text-align: center;
}

.structure_bt .r {
    float: left;
    margin-left: 40px;
    margin-top: 20px;
    width: 400px;
    text-align: left;
    white-space: nowrap;
    font-size: 130px;
    line-height: 80%;
    color: #e64a19;
}

.structure_bt .r em {
    font-family: 'Hattori Hanzo Rouble';
    font-style: normal;
}

.structure_bt .l .s1 {
    font-size: 66px;
}

.structure_bt .l .s2 {
    font-size: 50px;
}

.form-block3 {
    padding: 20px 0 60px 0;
}

.docid76 .form-block3 {
    /*padding: 0;*/
}

.form-block .wrap {
    width: 1200px;
    margin: 100px auto;
    text-align: center;
}

.form-block .wrap form .form__title {
    font-size: 50px;
    line-height: 1.1;
}

.form-block .wrap form span {
    font-size: 36px;
}

.form-block .wrap form > div {
    margin: 30px 0 20px 0;
}

.form-block .wrap form input {
    border: 5px solid #616161;
    border-radius: 5px;
    padding: 20px 30px;
    text-align: center;
    font-size: 36px;
    transition: .2s;
    margin-right: 20px;
    outline: none;
}

.form-block .wrap form div input:last-child {
    margin-right: 0;
}

.form-block .wrap form input:hover {
    border-color: #e64a19;
}

.form-block .wrap form button {
    padding: 20px 90px;
    font-size: 36px;
}

.map-block {
    opacity: 0;
    transition: 1.5s;
    background-position: center 170px;
    background-repeat: no-repeat;
    padding-bottom: 60px;
}

.map-block .wrap {
    position: relative;
    width: 1200px;
    height: 880px;
    margin: 0 auto;
    overflow: visible !important;
    z-index: 10;
    text-align: center;
}

.map-block h2 {
    text-align: center;
    margin: 60px 0 40px 0;
}

.map-block .p1 {
    /*margin-top: 740px;*/
    font-size: 36px;
}

.map-block .p2 {
    position: absolute;
    top: 290px;
    right: 265px;
    text-align: right;
    font-size: 70px;
    color: #ffffff;
}

.map-block .p3 {
    margin-top: -30px;
    font-size: 36px;
}

.delivery-payment .wrap {
    width: 1200px;
    margin: 0 auto;
}

.delivery-payment .wrap > p {
    font-size: 36px;
    text-align: center;
    margin-top: 120px;
}

.delivery-payment .wrap h2 {
    text-align: center;
    margin: 30px 0;
}

.delivery-payment .wrap ul {
    list-style-type: none;
    /*display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap:wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    -ms-flex-pack: center;
    -webkit-justify-content: space-between;
    */
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
}

.delivery-payment .wrap ul li {
    height: 143px;
    width: 141px;
    background-position: top left 0;
    background-repeat: no-repeat;
    position: relative;
    opacity: 0;
    transition: 1.5s;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.delivery-payment .wrap ul.payment li:nth-child(2) {
    background-position: top left -141px
}

.delivery-payment .wrap ul.payment li:nth-child(3) {
    background-position: top left -282px
}

.delivery-payment .wrap ul.payment li:nth-child(4) {
    background-position: top left -423px
}

.delivery-payment .wrap ul.payment li:nth-child(5) {
    background-position: top left -564px
}

.delivery-payment .wrap ul.payment li:nth-child(6) {
    background-position: top left -705px
}

.delivery-payment .wrap ul.delivery {
    padding-bottom: 250px;
}

.delivery-payment .wrap ul.delivery li:nth-child(2) {
    background-position: top left -141px
}

.delivery-payment .wrap ul.delivery li:nth-child(3) {
    background-position: top left -282px
}

.delivery-payment .wrap ul.delivery li:nth-child(4) {
    background-position: top left -423px
}

.delivery-payment .wrap ul.delivery li:nth-child(5) {
    background-position: top left -564px
}

.delivery-payment .wrap ul.delivery li:nth-child(6) {
    background-position: top left -705px
}

.delivery-payment .wrap ul li:before {
    position: absolute;
    content: attr(data-title);
    top: 163px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 22px;
}

.delivery-payment .wrap ul.delivery li:before {
    content: attr(data-text);
    top: 203px;
    font-size: 16px;
}

.delivery-payment .wrap ul.delivery li:after {
    position: absolute;
    content: attr(data-title);
    top: 163px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 22px;
}

.manufacture {
    padding-bottom: 100px;
    opacity: 0;
    transition: 1.5s;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.manufacture .wrap {
    width: 1200px;
    margin: 0 auto;
}

.manufacture .wrap h2 {
    text-align: center;
    margin: 30px 0;
    padding-top: 80px;
    color: white;
}

.manufacture .wrap h2 a {
    color: white;
}

.manufacture .wrap #sm_slider div.img {
    width: 317px;
    height: 250px;
    background-position: top left 0;
    background-repeat: no-repeat;
    position: relative;
    opacity: 0;
    transition: 1.5s;
    display: inline-block;
}

.manufacture .wrap ul.thesis {
    list-style-type: none;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: center;
    padding: 0;
    color: white;
    margin-top: 100px;
}

.manufacture .wrap ul.thesis li:nth-child(3n+2) {
    width: 128px;
    height: 180px;
    background-position: top left 0;
    background-repeat: no-repeat;
    position: relative;
    opacity: 0;
    transition: 1.5s;
}

.manufacture .wrap ul.thesis li:nth-child(3n+3) {
    width: 50%;
    font-size: 22px;
    line-height: 1.3;
}

.manufacture .wrap ul.thesis li:nth-child(3n+4),
.manufacture .wrap ul.thesis li:first-child {
    width: 30%;
    font-size: 36px;
    text-align: right;
}

.manufacture .wrap ul.thesis li:nth-child(2) {
    background-position: top left
}

.manufacture .wrap ul.thesis li:nth-child(5) {
    background-position: top left -128px
}

.manufacture .wrap ul.thesis li:nth-child(8) {
    background-position: top left -256px
}

.manufacture .wrap ul.thesis li:nth-child(11) {
    background-position: top left -384px
}

.manufacture .wrap ul.thesis li:nth-child(14) {
    background-position: top left -512px
}

.opt-form .wrap {
    width: 1200px;
    margin: 140px auto 100px;
    text-align: center;
}

.opt-form .wrap form {
    position: relative;
}

.opt-form .wrap form:before {
    content: attr(data-mark);
    position: absolute;
    top: -75px;
    left: 50px;
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
    text-align: center;
    padding: 12px 20px;
    text-transform: uppercase;
    font-size: 36px;
    background: #d50000;
    color: white;
}

.opt-form .wrap form .form__title {
    font-size: 50px;
}

.opt-form .wrap form span {
    font-size: 36px;
}

.opt-form .wrap form > div {
    margin: 30px 0;
}

.opt-form .wrap form input {
    border: 5px solid #616161;
    border-radius: 5px;
    padding: 20px 30px;
    text-align: center;
    font-size: 36px;
    transition: .2s;
    margin-right: 20px;
    outline: none;
}

.opt-form .wrap form div input:last-child {
    margin-right: 0;
}

.opt-form .wrap form input:hover {
    border-color: #e64a19;
}

.opt-form .wrap form button {
    padding: 20px 90px;
    font-size: 36px;
}

.certs {
    background: #29b6f6;
    padding-bottom: 50px;
}

.certs .wrap {
    width: 1200px;
    margin: 0 auto;
}

.certs .wrap h2 {
    text-align: center;
    margin: 30px 0;
    padding-top: 60px;
    color: white;
}

.certs .wrap #certsSlide div.img {
    width: 239px;
    height: 327px;
    background-position: top left 0;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    opacity: 0;
    transition: 1.5s;
    display: inline-block;
    margin-left: -10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
}

.certs .wrap #certsSlide div.img:before {
    position: absolute;
    content: attr(data-title);
    bottom: -35px;
    left: 0;
    right: 0;
    font-size: 22px;
    color: white;
    text-align: center;
}

.certs .wrap #certsSlide .sm_slider-inner {
    margin: 0;
    padding: 0;
}

.reviews {
    position: relative;
    padding-bottom: 100px;
    opacity: 0;
    transition: 1.5s;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.reviews:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.reviews .wrap {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    z-index: 10;
}

.reviews .wrap h2 {
    text-align: center;
    margin: 0 0 30px;
    padding-top: 60px;
    color: white;
}

.reviews .wrap h2 a {
    color: white;
}

#reviews_slider {
    color: white;
}

#reviews_slider ul {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

#reviews_slider li {
}

#reviews_slider .img {
    width: 250px;
    height: 250px;
    border-radius: 125px;
    margin: 10px auto 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

#reviews_slider,
#reviews_slider .title {
    font-size: 36px;
    color: white;
    margin: 0 auto;
    width: 1000px;
    white-space: normal;
    line-height: 1.3;
}

#reviews_slider .link,
#reviews_slider .link a {
    font-size: 22px;
    color: white;
}

#reviews_slider .link {
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 10px;
    position: relative;
}

#reviews_slider .link:before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 150px;
    background: #e64a19;
    height: 5px;
    margin-left: -75px;
}

#reviews_slider .desc {
    font-size: 22px;
    color: white;
    white-space: normal;
    text-align: center;
    max-width: 950px;
    margin: 0 auto;
    height: 200px;
}

.clients .wrap {
    width: 1200px;
    margin: 0 auto;
}

.clients .wrap h2 {
    text-align: center;
    margin-bottom: 10px;

}

.clients__block {
    margin: 0 2%;
    padding-top: 20px;
    font-size: 22px;
}

.gallery {
    margin-bottom: 100px;
}

.gallery .wrap {
    width: 1200px;
    margin: 0 auto;
}

.gallery .wrap h2 {
    text-align: center;
    padding-top: 30px;
    margin-bottom: 30px;

}

.gallery .wrap #gallery-slider ul {
    overflow: hidden;
}

.gallery .wrap #gallery-slider ul li {
    /*float: left;*/
}

.gallery .wrap #gallery-slider ul li.sm_slide {
    float: none;
}

.gallery .wrap #gallery-slider .slide {
    overflow: hidden;
}

.gallery .wrap #gallery-slider .slide div {
    float: left;
    margin: 10px;
    width: 313px;
    height: 220px;
}

.footer {
    opacity: 0;
    transition: 1.5s;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    padding-bottom: 50px;
    display: block;
}

.footer .wrap {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
}

.wrap_up {
    position: relative;
    width: 1200px;
    margin: 0 auto;
}

.footer .wrap form {
    padding-bottom: 40px;
    position: relative;
    text-align: center;
}

.footer .wrap form .form__title {
    margin: 40px 0 10px 0;
    font-size: 50px;
    color: white;
}

.footer .wrap form span {
    font-size: 36px;
}

.footer .wrap form > div {
    margin: 30px 0 20px 0;
}

.footer .wrap form input {
    border: 5px solid #616161;
    border-radius: 5px;
    padding: 20px 30px;
    text-align: center;
    font-size: 36px;
    transition: .2s;
    margin-right: 20px;
    outline: none;
}

.footer .wrap form div input:last-child {
    margin-right: 0;
}

.footer .wrap form input:hover {
    border-color: #e64a19;
}

.footer .wrap form button {
    padding: 20px 90px;
    font-size: 36px;
}

.footer .wrap .contacts,
footer .wrap .contacts {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    /*float: left;*/
    /*width: 250px;*/
    font-size: 22px;
    line-height: 1.2;
}

.footer .wrap .contacts a,
footer .wrap .contacts a {
    color: white;
    /*text-decoration: none;*/
}

.footer .wrap .contacts a.block,
footer .wrap .contacts a.block {
    display: block;
    text-decoration: underline;
}

.footer .wrap .social,
footer .wrap .social {
    float: right;
    font-size: 26px;
    width: 240px;
    text-align: center;
    line-height: 1.3;
}

.footer .wrap .social p,
footer .wrap .social p {
    margin-top: 0;
    margin-bottom: 10px;
}

.footer .wrap .social a,
footer .wrap .social a {
    display: inline-block;
    width: 62px;
    height: 63px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 10px;
}

footer {
    opacity: 0;
    transition: 1.5s;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    padding: 50px 0 100px;
}

footer .wrap {
    overflow: hidden;
    width: 1200px;
    margin: 0 auto;
}

#light, #popup {
    display: none;
}

#light {
    background: #000;
    opacity: .7;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

#popup {
    background: white;
    position: fixed;
    top: 10%;
    left: 50vw;
    width: 800px;
    border: 5px solid #e64a19;
    border-radius: 5px;
    z-index: 1001;
    margin-left: -400px;
    padding: 20px;
    padding-bottom: 50px;
}

#popup div.close {
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
    z-index: 10;
}

#popup div.close span {
    display: inline-block;
    background: url("/img/close.jpg") no-repeat center;
    height: 44px;
    width: 44px;
    float: right;
    cursor: pointer;
}

#popup form {
    position: relative;
    margin: 0 auto;
    text-align: center;
}

#popup h2 {
    margin-bottom: 20px;
    font-size: 50px;
    position: relative;
}

#popup h2:before {
    position: absolute;
    content: '';
    bottom: -40px;
    height: 5px;
    background: #e64a19;
    width: 150px;
    left: 0;
    right: 0;
    margin-left: -75px;
}

#popup form h2:before {
    height: 0;
}

#popup form span {
    font-size: 36px;
}

#popup form > div {
    margin: 60px 0;
}

#popup form input {
    border: 5px solid #616161;
    border-radius: 5px;
    padding: 20px 30px;
    text-align: center;
    font-size: 22px;
    transition: .2s;
    margin-right: 20px;
    outline: none;
}

#popup form div input:last-child {
    margin-right: 0;
}

#popup form input:hover {
    border-color: #e64a19;
}

#popup form button {
    padding: 20px 90px;
    font-size: 36px;
}

#popup .text h2 {
    text-align: center;
    margin-bottom: 60px;
}

#popup .text h2:before {
    position: absolute;
    content: '';
    bottom: -20px;
    height: 5px;
    background: #e64a19;
    width: 150px;
    left: 50%;
    right: 0;
    margin-left: -75px;
}

#popup .text p {
    font-size: 36px;
    text-align: center;
    margin: 0;
    line-height: 1.2;
}

#popup .text.policy p {
    font-size: 22px;
    text-align: left;
}

.footer .wrap .social a:nth-child(2),
footer .wrap .social a:nth-child(2) {
    background-position: top left -62px
}

.footer .wrap .social a:nth-child(3),
footer .wrap .social a:nth-child(3) {
    background-position: top left -124px
}

button.upload {
    margin: 20px 90px 0 0;
    padding: 20px 40px !important;
    background-color: #18B341;
}

button.upload.disable {
    padding: 20px 20px !important;
    background-color: #AFB3B0;
}

/* --- up --- */
a.up {
    position: fixed;
    top: 100%;
    z-index: 4;
    display: none;
    margin-top: -170px;
    margin-left: 1160px;
    width: 47px;
    height: 37px;
    background: url(../img/up.png) no-repeat;
    opacity: 0.8;
    cursor: pointer;
}

.toTop {
    position: fixed;
    display: block;
    bottom: 35px;
    left: 15px;
    margin: 0;
    text-align: center;
    background: none !important;
    background-color: transparent;
    font-size: 22px;
    color: #1a1a1a;
    cursor: pointer;
    z-index: 1000;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.toTop s {
    display: block;
    margin: 0 auto;
    margin-bottom: -2px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #1a1a1a;
}

.toTop:hover {
    opacity: 1 !important;
}

.content {
    padding: 40px 0;
}

.content .wrap {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 30px;
    width: 1200px;
}

.content h2 {
    /*margin: 0;*/
    /*margin-bottom: 30px;*/
    margin: 20px auto 20px auto;
    text-align: center;
}
.content .t,
.content h5 {
	margin-bottom: 15px;
    text-align: center;
    font-size: 42px;
    font-weight: normal;
}
.content table {
    width: 100%;
}

.content form h2 {
    margin-bottom: 0;
}

.content img {
    display: block;
    /*margin: 10px 10px 30px 10px;*/
}

.content div {
    clear: both;
}

.content ol,
.content ul {
    list-style-position: inside;
    padding-left: 0;
}

.content li {
    margin-top: 10px;
}

.content li,
.content p {
    line-height: 140%;
    font-family: "Helvetica Neue", Arial, "Liberation Sans", FreeSans, sans-serif;
    font-family: 'Hattori Hanzo', sans-serif, serif;
    font-size: 22px;
    text-align: justify;
    text-indent: 30px;
}

.content h3 {
    padding: 30px 0 5px 0;
    text-align: center;
    font-size: 36px;
}

.content p + h1 {
    margin-top: 20px;
}

.content h1,
.con_heading {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    font-family: 'Hattori Hanzo', sans-serif, serif;
    overflow: hidden;
}
.docid76 br+img {
    margin-top: 10px;
}
.docid76 p.t {
    text-align: left;
}
.content img:not(.fl) + img {
    margin-top: 10px;
}
.docid73 table img,
.docid84 table img,
.docid75 table img {
    margin-right: 20px;
}
.docid80 img {
    margin-bottom: 10px;
}
.docid78 .content h2#anchor4,
.docid78 .content h2#anchor5 {
    margin-top: 50px;
}

.orange {
    color: #e64a19;
}

.action-fix {
    position: fixed;
    top: 45%;
    right: 0;
    padding: 10px 10px 10px 20px;
    border-radius: 10px 0 0 10px;
    border: 2px solid #d50000;
    border-right: none;
    background-color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    z-index: 20;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    cursor: pointer;
}

.action-fix:hover {
    background-color: rgba(255, 255, 255, 1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.action-fix .s {
    font-size: 22px;
    line-height: 1.4;
}

.action-fix button {
    margin-top: 10px;
}

.formAfter {
    margin: 20px 0 0 0 !important;
    font-size: 36px;
}

.agreement {
    display: inline-block;
    padding: 30px 0 10px 0;
    text-align: center;
}

.agreement span {
    font-size: 22px !important;
}

.agreement a {
    border-bottom: 1px dashed #55595c;
    text-decoration: none;
    color: #1a1a1a;
}

.footer .agreement a {
    border-color: #fff;
    color: #fff;
}

.bPopup__text,
.bPopup__text p {
    font-size: 14px !important;
    text-align: center;
}

.bPopup__text a {
    color: #1a1a1a;
}

.bPopup__text ul {
    margin: 0;
    list-style-position: inside;
    /*display: inline-block;*/
}

.bPopup__text p + p {
    /*padding: 8px 0 0 0;*/
}

.bPopup__title {
    font-weight: bold;
}

.titleT {
    margin-bottom: 10px;
    text-align: center;
    font-size: 50px;
}

/*404*/
.docid31 .block-1 .wrap>div:last-child,
.docid31 .footer .wrap form,
.docid31 .action-fix {
	display: none;
}
.docid31 .block-1,
.docid31 .block-1 .wrap {
	min-height: 280px;
	height: 280px !important;
}

#sm_slider ul.sm_nav, #reviews_slider ul.sm_nav, #gallery-slider ul.sm_nav, #items-slide ul.sm_nav {
    margin-left: -13% !important;
}

#popup form.exit > div {
    margin: 30px 0;
}
form.exit {
    padding-top: 130px;
}
form.exit:before {
    content: '';
    position: absolute;
    top: -80px;
    left: 210px;
    right: 0;
    border: 1px solid #1a1a1a;
    width: 418px;
    height: 183px;
    background: url('../img/present1000.jpg') 50% no-repeat;
}
form.exit .agreement {
    padding: 10px 0 0 0;
}

